<template>
  <v-snackbar top v-model="showSnackbar" color="error" :timeout="3000">
    <v-row align="center">
      <v-icon class="mx-3">mdi-close</v-icon>
      <v-col>
        <span>{{ snackbarText }}</span>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
  
  <script>
export default {
  data: () => ({
    showSnackbar: true,
  }),
  props: {
    snackbarText: {
      require: true,
    },
  },
  watch: {
    showSnackbar: {
      handler() {
        if (!this.snackbarText) {
          this.$emit("hidde");
        }
      },
    },
  },
};
</script>
  
  <style>
</style>