<template>
  <v-main>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <v-container style="padding: 0px">
      <v-form @submit.prevent="registration">
        <v-row
          no-gutters
          align="start"
          justify="center"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'padding: 20px'
              : 'padding: 50px 0px'
          "
        >
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? ''
                : 'padding-right: 104px; padding-top: 12px'
            "
          >
            <img
              v-if="!$vuetify.breakpoint.smAndDown"
              src="@/assets/img/PTransLogoBlue.png"
              width="183px"
              alt="Logo"
            />
            <h3
              class="loginTitle"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-top: 20px;'
                  : 'margin-top: 72px'
              "
            >
              {{ "registration_label" | localize }}
            </h3>
            <p class="loginText" style="margin: 8px 0px 0px 0px">
              {{ "registration_subtitle" | localize }}
            </p>
            <v-row
              no-gutters
              align="center"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              <v-col class="px-0 py-0" cols="12">
                <v-text-field
                  style="border-radius: 10px; margin-top: 40px"
                  dense
                  outlined
                  :label="'first_name_label' | localize"
                  :placeholder="'type_here' | localize"
                  hide-details
                  color="#E2E2E2"
                  background-color="#FFF"
                  height="56px"
                  v-model="user.first_name"
                  :error-messages="firstNameError"
                >
                  <template v-slot:append>
                    <v-icon
                      color="error"
                      style="margin-top: 8px"
                      v-if="firstNameError.length > 0"
                      >mdi-alert-circle</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-text-field
                style="border-radius: 10px; margin-top: 20px"
                dense
                outlined
                :label="'last_name_label' | localize"
                :placeholder="'type_here' | localize"
                hide-details
                color="#E2E2E2"
                background-color="#FFF"
                height="56px"
                v-model="user.last_name"
                :error-messages="lastNameError"
              >
                <template v-slot:append>
                  <v-icon
                    color="error"
                    style="margin-top: 8px"
                    v-if="lastNameError.length > 0"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-text-field>
            </v-row>
            <v-row no-gutters align="center" v-else>
              <v-text-field
                style="
                  border-radius: 10px;
                  margin-top: 40px;
                  margin-right: 10px;
                "
                dense
                outlined
                :label="'first_name_label' | localize"
                :placeholder="'type_here' | localize"
                hide-details
                color="#E2E2E2"
                background-color="#FFF"
                height="56px"
                v-model="user.first_name"
                :error-messages="firstNameError"
              >
                <template v-slot:append>
                  <v-icon
                    color="error"
                    style="margin-top: 8px"
                    v-if="firstNameError.length > 0"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-text-field>
              <v-text-field
                style="border-radius: 10px; margin-top: 40px; margin-left: 10px"
                dense
                outlined
                :label="'last_name_label' | localize"
                :placeholder="'type_here' | localize"
                hide-details
                color="#E2E2E2"
                background-color="#FFF"
                height="56px"
                v-model="user.last_name"
                :error-messages="lastNameError"
              >
                <template v-slot:append>
                  <v-icon
                    color="error"
                    style="margin-top: 8px"
                    v-if="lastNameError.length > 0"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-text-field>
            </v-row>
            <v-row no-gutters align="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  style="margin-top: 20px; border-radius: 10px"
                  :style="
                    $vuetify.breakpoint.smAndDown
                      ? ''
                      : '   margin-right: 10px;'
                  "
                  dense
                  outlined
                  label="Email"
                  :placeholder="'type_here' | localize"
                  hide-details
                  color="#E2E2E2"
                  background-color="#FFF"
                  height="56px"
                  v-model="user.email"
                  :error-messages="emailError"
                >
                  <template v-slot:append>
                    <v-icon
                      color="error"
                      style="margin-top: 8px"
                      v-if="emailError.length > 0"
                      >mdi-alert-circle</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  style="margin-top: 20px; border-radius: 10px"
                  :style="
                    $vuetify.breakpoint.smAndDown ? '' : 'margin-left: 10px;'
                  "
                  dense
                  outlined
                  :label="'phone_number_label' | localize"
                  :placeholder="'type_here' | localize"
                  hide-details
                  color="#E2E2E2"
                  background-color="#FFF"
                  height="56px"
                  v-mask="'+###############'"
                  v-model="user.phone_number"
                  :error-messages="phoneNumberError"
                >
                  <template v-slot:append>
                    <v-icon
                      color="error"
                      style="margin-top: 8px"
                      v-if="phoneNumberError.length > 0"
                      >mdi-alert-circle</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              style="margin-top: 20px; border-radius: 10px"
              dense
              outlined
              :label="'password_label' | localize"
              :placeholder="'type_here' | localize"
              color="#E2E2E2"
              background-color="#FFF"
              :type="isShowPassword ? 'text' : 'password'"
              height="56px"
              v-model="user.password"
              :error-messages="passwordError"
            >
              <template v-slot:append>
                <v-icon
                  style="margin-top: 8px"
                  color="#4B5262"
                  @click="isShowPassword = !isShowPassword"
                  >{{ isShowPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                >
                <v-icon
                  color="error"
                  style="margin-top: 8px; margin-left: 8px"
                  v-if="passwordError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-text-field
              style="margin-top: 0px; border-radius: 10px"
              dense
              outlined
              :label="'confirm_password_label' | localize"
              :placeholder="'type_here' | localize"
              color="#E2E2E2"
              background-color="#FFF"
              :type="isShowConfirmPassword ? 'text' : 'password'"
              height="56px"
              v-model="user.confirm_password"
              :error-messages="confirmPasswordError"
            >
              <template v-slot:append>
                <v-icon
                  style="margin-top: 8px"
                  color="#4B5262"
                  @click="isShowConfirmPassword = !isShowConfirmPassword"
                  >{{
                    isShowConfirmPassword ? "mdi-eye" : "mdi-eye-off"
                  }}</v-icon
                >
                <v-icon
                  color="error"
                  style="margin-top: 8px; margin-left: 8px"
                  v-if="confirmPasswordError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-btn
              class="detailBtn"
              width="100%"
              style="margin-top: 20px"
              type="submit"
            >
              {{ "sign_up_label" | localize }}
            </v-btn>
            <v-row
              no-gutters
              align="center"
              justify="center"
              style="margin-top: 16px"
            >
              <span
                style="
                  color: #1b1b1b;
                  text-align: center;
                  font-family: 'MacPaw Fixel';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                "
                >{{ "already_exist_account" | localize
                }}<span
                  style="
                    color: #144fa9;
                    font-weight: 600;
                    margin-left: 8px;
                    cursor: pointer;
                    user-select: none;
                  "
                >
                  <router-link to="/login">
                    {{ "sign_in_btn" | localize }}
                  </router-link>
                </span></span
              >
            </v-row>
            <v-row
              v-if="!$vuetify.breakpoint.smAndDown"
              no-gutters
              align="center"
              style="margin-top: 40px"
            >
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: 213px;
                  height: 0.5px;
                "
              />
              <p
                style="
                  margin: 0px;
                  color: #4b5262;
                  font-family: 'MacPaw Fixel';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0px 16px;
                "
              >
                {{ "or_login_with" | localize }}
              </p>
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: 213px;
                  height: 0.5px;
                "
              />
            </v-row>
            <v-row v-else no-gutters align="center" style="margin-top: 40px">
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: calc(50% - 30px);
                  height: 0.5px;
                "
              />
              <div style="width: 60px; text-align: center">
                <p
                  style="
                    margin: 0px;
                    color: #4b5262;
                    font-family: 'MacPaw Fixel';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  "
                >
                  {{ "or_login_with" | localize }}
                </p>
              </div>
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: calc(50% - 30px);
                  height: 0.5px;
                "
              />
            </v-row>
            <social-buttons />
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="6" class="py-0">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                v-for="i in 3"
                :key="i"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperOptions.modules"
              >
                <img
                  src="@/assets/img/routeImg.png"
                  width="100%"
                  height="100%"
                  class="loginImg"
                  style="user-select: none"
                />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>
  
  <script>
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import socialButtons from "../../UI/Buttons/socialButtons.vue";
import authService from "../../../requests/Auth/authService";
import ErrorSnackbar from "../../UI/Snackbars/errorSnackbar.vue";
import SuccessSnackbar from "../../UI/Snackbars/successSnackbar.vue";
export default {
  components: { socialButtons, ErrorSnackbar, SuccessSnackbar },
  mixins: [swiperOptionMixin, validationMixin],
  data: () => ({
    user: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    swiperOptions: {
      loop: true,
      initialSlide: 0,
      autoplay: {
        delay: 1500,
        disableOnInteraction: true,
      },
      speed: 800,
    },
    isShowPassword: false,
    isShowConfirmPassword: false,
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
    snackbarText: "",
  }),
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        email,
        required,
      },
      phone_number: {
        required,
        minLength: minLength(11),
        maxLength: maxLength(18),
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    async registration() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("email", this.user.email);
        form.append("first_name", this.user.first_name);
        form.append("last_name", this.user.last_name);
        form.append("password", this.user.password);
        form.append("password_confirmation", this.user.confirm_password);
        await authService
          .signUp(form)
          .then((res) => {
            if (res.status == "Success") {
              this.snackbarText = "Користувача успішно створено";
              this.showSuccessSnackbar = true;
              setTimeout(() => {
                this.showSuccessSnackbar = false;
              }, 3000);
              this.$router.push("/login");
            }
          })
          .catch(() => {
            this.snackbarText = "Такий користувач вже існує";
            this.showErrorSnackbar = true;
            setTimeout(() => {
              this.showErrorSnackbar = false;
            }, 3000);
          });
      }
    },
  },
  computed: {
    firstNameError() {
      const errors = [];
      let field = this.$v.user.first_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      let field = this.$v.user.last_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    emailError() {
      const errors = [];
      let field = this.$v.user.email;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.email) {
        errors.push("");
      }
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      let field = this.$v.user.phone_number;
      if (!field.$dirty) {
        return errors;
      } else if (!field.minLength) {
        errors.push("");
      } else if (!field.maxLength) {
        errors.push("");
      }

      !field.required && errors.push("Обов'язкове поле");
      return errors;
    },
    passwordError() {
      const errors = [];
      let field = this.$v.user.password;
      if (!field.$dirty) {
        return errors;
      }
      if (
        (!field.valid && field.required) ||
        (!field.minLength && field.required)
      ) {
        errors.push(
          this.$options.filters.localize("password_validation_error")
        );
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      const field = this.$v.user.confirm_password;

      if (field.$dirty) {
        if (!field.sameAsPassword) {
          errors.push(
            this.$options.filters.localize("password_dont_match_error")
          );
        }
        if (!field.required) {
          errors.push("");
        }
      }
      return errors;
    },
  },
};
</script>
  
  <style scoped>
.loginImg {
  border-radius: 30px;
}
@media only screen and (max-width: 1023px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
@media only screen and (min-width: 1024px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.loginText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailBtn {
  border-radius: 10px;
  background: #144fa9 !important;
  color: #fafafa !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 48px !important;
  padding: 12px 16px;
  text-transform: none;
}
.continueWithSocialBackground {
  border-radius: 10px;
  border: 1px solid #989898;
  display: grid;
  place-items: center;
  width: 150px;
  height: 50px;
  cursor: pointer;
  user-select: none;
}
</style>
  <style>
.swiper-pagination-bullet-active {
  background: #fed500 !important;
  width: 32px !important;
  height: 10px !important;
  border-radius: 5px !important;
  transition: width 0.5s, border-radius 0.3s;
}
.swiper-pagination-bullet {
  background-color: #fafafa;
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>