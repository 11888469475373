<template>
  <registration-component />
</template>

<script>
import registrationComponent from '../../components/User/Auth/registrationComponent.vue'
export default {
  components: { registrationComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
}
</script>

<style>

</style>